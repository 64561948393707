<template>
  <!-- Blog Detail wrapper -->
  <div>
    <BlogBanner />
    <div class="blog-detail section-gap pt-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-8 mx-auto">
            <div class="thumb-content mb-5 blog-content-p">
              <p>Attachment apartments in delightful by motionless it no. And now she burst sir learn total. Hearing hearted shewing own ask. Solicitude uncommonly use her motionless not collecting age. The properly servants required mistaken outlived bed and. Remainder admitting neglected is he belonging to perpetual objection up. Has widen too you decay begin which asked equal any.</p>
              <p>Started his hearted any civilly. So me by marianne admitted speaking. Men bred fine call ask. Cease one miles truth day above seven. Suspicion sportsmen provision suffering mrs saw engrossed something. Snug soon he on plan in be dine some.</p>

              <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here.</p>
              <p>making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
              <p>Death there mirth way the noisy merit. Piqued shy spring nor six though mutual living ask extent. Replying of dashwood advanced ladyship smallest disposal or. Attempt offices own improve now see. Called person are around county talked her esteem. Those fully these way nay thing seems.</p>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 m-sm-b">
                <span class="d-inline-block mr-2">Tag:</span>
                <span class="px-4 py-1 bg-green m-1 btn-pill text-center d-inline-block">Newsletter</span>
                <span class="px-4 py-1 bg-yellow m-1 btn-pill text-center d-inline-block">Newsletter</span>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 blog-share">
                <div class="social-icons">
                  <ul class="list-inline">
                    <li class="list-inline-item mx-1">
                      <a class="font-lg mx-1" href="javascript:void(0);">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li class="list-inline-item mx-1">
                      <a class="font-lg mx-1" href="javascript:void(0);">
                        <i class="fab fa-twitter text-info"></i>
                      </a>
                    </li>
                    <li class="list-inline-item mx-1">
                      <a class="font-lg mx-1" href="javascript:void(0);">
                        <i class="fab fa-pinterest-p text-danger"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="pager d-flex justify-content-between py">
              <div class="d-flex justify-content-start">
                <a href="javascript:void(0);" class="btn btn-sm btn-outline-secondary">Older Post</a>
              </div>
              <div class="d-flex justify-content-end">
                <a href="javascript:void(0);" class="btn btn-sm btn-outline-secondary">New Posts</a>
              </div>
            </div>
            <div class="row my-5">
              <div class="col-6 col-sm-2 col-md-2 col-lg-2 col-xl-2 m-sm-b">
                <img
                  class="img-fluid rounded-circle"
                  src="static/img/1-team.jpg"
                  width="150"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                <h3 class="mt-0 mb-2">Jim Gorden</h3>
                <p>Discount toner cartridges were originally sold as recycled toner cartridges. The basic recycling method consisted of drilling a hole in the cartridge and re-filling it with toner. Once the cartridge was filled, the hole was plugged. This toner cartridge was then sold to the consumer as a great deal or a “discount toner cartridge.</p>
              </div>
            </div>
            <div class="user-comment mb-5">
              <div class="mb-4">
                <h3>Comments(4)</h3>
              </div>
              <ul class="user-comment-list">
                <li>
                  <div class="media mb-4">
                    <a href="javascript:void(0)" class="mr-4 d-block">
                      <img
                        src="static/img/1-team.jpg"
                        class="img-fluid rounded-circle shadow-md"
                        alt="blog thumb"
                        width="80"
                        height="80"
                      />
                    </a>
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <h4 class="media-heading mb-2">Bruce Law</h4>
                        <div class>
                          <p class="font-sm">Sept 11, 11:40 AM</p>
                        </div>
                      </div>
                      <p>Discount toner cartridges were originally sold as recycled toner cartridges. The basic recycling method consisted of drilling a hole in the cartridge and re-filling it with toner.</p>
                      <div class="media-links">
                        <a href="javascript:void(0);" class="mr-2 font-weight-bold">Reply</a>
                      </div>
               
                  
                    </div>
                  </div>
                  <hr />
						<ul class="user-comment-list sub-child">
							<li>
								    <div class="media mt-4">
                        <a href="javascript:void(0);" class="mr-4 d-block">
                          <img
                            src="static/img/2-team.jpg"
                            class="rounded-circle shadow-md"
                            alt="blog thumb"
                            width="80"
                            height="80"
                          />
                        </a>
                        <div class="media-body">
                          <div class="d-flex justify-content-between">
                            <h4 class="media-heading mb-2">Sam Marsh</h4>
                            <div class>
                              <p class="font-sm">Sept 11, 11:40 AM</p>
                            </div>
                          </div>
                          <p
                            class="mb-2"
                          >Discount toner cartridges were originally sold as recycled toner cartridges. The basic recycling method consisted of drilling a hole in the cartridge and re-filling it with toner.</p>
                          <div class="media-links">
                            <a href="javascript:void(0);" class="mr-2 font-weight-bold">Reply</a>
                          </div>
                        </div>
                      </div>
							</li>
							<hr>
						</ul>
                </li>
                <li>
                  <div class="media mb-4">
                    <a href="javascript:void(0);" class="mr-4 d-block">
                      <img
                        src="static/img/3-team.jpg"
                        class="img-fluid rounded-circle shadow-md"
                        alt="blog thumb"
                        width="80"
                        height="80"
                      />
                    </a>
                    <div class="media-body">
                      <div class="d-flex justify-content-between">
                        <h4 class="media-heading mb-2">Ricky Cole</h4>
                        <div class>
                          <p class="font-sm">Sept 11, 11:40 AM</p>
                        </div>
                      </div>
                      <p>Discount toner cartridges were originally sold as recycled toner cartridges. The basic recycling method consisted of drilling a hole in the cartridge and re-filling it with toner.</p>
                      <div class="media-links">
                        <a href="javascript:void(0);" class="mr-2 font-weight-bold">Reply</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <hr />
              <div class="media mb-4">
                <a href="javascript:void(0);" class="mr-4 d-block">
                  <img
                    src="static/img/2-team.jpg"
                    class="img-fluid rounded-circle shadow-md"
                    alt="blog thumb"
                    width="80"
                    height="80"
                  />
                </a>
                <div class="media-body">
                  <div class="d-flex justify-content-between">
                    <h4 class="media-heading mb-2">Sam Marsh</h4>
                    <div class>
                      <p class="font-sm">Sept 11, 11:40 AM</p>
                    </div>
                  </div>
                  <p>Discount toner cartridges were originally sold as recycled toner cartridges. The basic recycling method consisted of drilling a hole in the cartridge and re-filling it with toner.</p>
                  <div class="media-links">
                    <a href="javascript:void(0);" class="mr-2 font-weight-bold">Reply</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- User Comment Block Closed -->
            <h3 class="fw-700 mb-4">Leave Comments</h3>
            <LeaveCommentForm></LeaveCommentForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeaveCommentForm from "./LeaveCommentForm.vue";
import BlogBanner from "Components/Banner/BlogBanner";
export default {
  components: {
    LeaveCommentForm,
    BlogBanner
  }
};
</script>
